import React from 'react'
import { Link as ScrollLink } from 'react-scroll'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Ecco (ˈɛkko). Adv., Italian.</h1>
            </header>
            <div className="content">
                <p>In Italian, Ecco means, “Here is.” Through our name, we want you to know that we are your complete design build resource. No need to look any further, Ecco (here is) design build.</p>
            </div>
            <div className="content">
              <p>
                ECCO Design Build <i>"A Passion for Quality"</i>
              </p>
              <ul className="actions">
                  <li><ScrollLink to="services" smooth={true} className="button">Services</ScrollLink></li>
              </ul>
            </div>
        </div>
    </section>
)

export default Banner
