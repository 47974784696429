import React from 'react'
import { Link } from 'gatsby'
import { Link as ScrollLink } from 'react-scroll'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import Tiles from '../components/Tiles'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="ECCO Design Build | Design, Drafting, and Construction Services"
                    meta={[
                        { name: 'description', content: 'Full-service contracting services from design to construction, based in Corona, California' },
                        { name: 'keywords', content: 'sample, something' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                  <section id="two">
                      <div className="inner">
                          <header className="major">
                              <h2>Projects We Work On</h2>
                          </header>
                          <p>We work on a variety of projects, including custom homes, modular homes, commercial tennant improvement, and ADA compliance.</p>
                      </div>
                  </section>
                  <section id="services">
                    <Tiles />
                  </section>
                  <section>
                      <div className="inner">
                          <header className="major">
                              <h2>Contact Us</h2>
                          </header>
                          <p>Our customer service team can be reached via email or by phone to answer any questions you may have. Please note: We are open Monday through Friday from 8am-5pm PST. We will respond to any e-mails or phone messages received outside of normal business hours as quickly as possible, in the order they were received.</p>
                          <ul className="actions">
                              <li><ScrollLink to="contact" smooth={true} className="button">Contact Information</ScrollLink></li>
                          </ul>
                      </div>
                  </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
